<template>
  <div>
    <div class="page">
      <div class="herd">
        <div class="herd1">
          <div :class="['herdtext1', show == 0 ? 'ys' : '']" @click="onxz1()">
            <div>首页</div>
            <div class="span" v-if="show == 0"></div>
          </div>
          <div :class="['herdtext2', show == 2 ? 'ys' : '']" @click="onxz3()">
            <div>充值</div>
            <span class="span" v-if="show == 2"></span>
          </div>
          <div :class="['herdtext2', show == 1 ? 'ys' : '']" @click="onxz2()">
            <div>关于我们</div>
            <span class="span" v-if="show == 1"></span>
          </div>
        </div>
        <div class="herd2">{{ appname }}</div>
      </div>
      <!-- 首页 -->
      <div class="nr1" v-if="show == 0" @click="onxiaz()">
        <!-- <img :src="img" class="nr1img" /> -->
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item><img :src="img" class="nr1img" /></van-swipe-item>
          <van-swipe-item><img :src="img_1" class="nr1img" /></van-swipe-item>
        </van-swipe>
      </div>
      <!-- 关于我们 -->
      <div class="nr2" v-if="show == 1">
        <div class="nr2text">联系方式：hnndkf@163.com</div>
      </div>
      <div v-if="show == 2" class="show2box">
        <div class="topinput"> 
            <input type="text" placeholder="请输入APP用户ID">
        </div>
        <div class="select_title">请选择金币套餐：</div>
        <div class="select_menue">
          <div :class="['menue_son', active ==1 ? 'active':'']"  @click="selectson(1)">190金币（19元）</div>
          <div :class="['menue_son', active ==2 ? 'active':'']" @click="selectson(2)">980金币（98元）</div>
          <div :class="['menue_son', active ==3 ? 'active':'']" @click="selectson(3)">2980金币（298元）</div>
        </div>
        <div class="select_wx">请选择支付方式：</div>
        <div class="wx">微信支付</div>
        <div class="clickgo" @click="clickgo">点击充值</div>
        <div class="loding" v-if="click"><van-loading type="spinner" /></div>
    </div>
      <div class="foot">
        <div class="foot-text1" v-if="icp != ''">
          <a href="https://beian.miit.gov.cn/" target="_blank">{{ icp }}</a>
        </div>
        <!-- 1 -->
        <div class="foot-text2" v-if="img == 'https://cdn.bzxd.tv/mydemo/demoimg1.png'">
          <span class="police">
            <img src="../assets/images/police.png" alt="" />
          </span>
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502019956"
            target="_blank"
            >沪公网安备 31011502019956号</a
          >
        </div>
        <!-- 3 -->
        <div class="foot-text2" v-if="img == 'https://cdn.bzxd.tv/mydemo/demoimg3.png'">
          <span class="police">
            <img src="../assets/images/police.png" alt="" />
          </span>
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502019956"
            target="_blank"
            >沪公网安备 31011502020108号</a
          >
        </div>
        <!-- 4 -->
        <div class="foot-text2" v-if="img == 'https://cdn.bzxd.tv/mydemo/demoimg4.png'">
          <span class="police">
            <img src="../assets/images/police.png" alt="" />
          </span>
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502019956"
            target="_blank"
            >沪公网安备 31011502020109号</a
          >
        </div>
        <!-- 5 -->
        <div class="foot-text2" v-if="img == 'https://cdn.bzxd.tv/mydemo/demoimg5.png'">
          <span class="police">
            <img src="../assets/images/police.png" alt="" />
          </span>
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502019956"
            target="_blank"
            >沪公网安备 31011502020110号</a
          >
        </div>
        <!--  jiubanapp -->
        <div class="foot-text2" v-if="img == 'https://cdn.bzxd.tv/mydemo/demoimg12.png'">
          <span class="police">
            <img src="../assets/images/police.png" alt="" />
          </span>
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502019956"
            target="_blank"
            >沪公网安备31010402333612号</a
          >
        </div>
        <!-- hnzhengqiang.cn -->
        <div class="foot-text2" v-if="img == 'https://cdn.bzxd.tv/mydemo/demoimg15.png'">
          <span class="police">
            <img src="../assets/images/police.png" alt="" />
          </span>
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502019956"
            target="_blank"
            >沪公网安备31010402333611号</a
          >
        </div>
        <!-- hehuanapp.cn -->
        <div class="foot-text2" v-if="img == 'https://cdn.bzxd.tv/mydemo/demoimg16.png'">
          <span class="police">
            <img src="../assets/images/police.png" alt="" />
          </span>
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502019956"
            target="_blank"
            >沪公网安备31010402333609号</a
          >
        </div>
        
            <!-- bzxd.top -->
            <div class="foot-text2" v-if="img == 'https://cdn.bzxd.tv/mydemo/demoimg6.png'">
          <span class="police">
            <img src="../assets/images/police.png" alt="" />
          </span>
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502019956"
            target="_blank"
            >川公网安备51019002006086号</a
          >
        </div>
        <!-- yqyunqing.cn -->
        <div class="foot-text2" v-if="img == 'https://cdn.bzxd.tv/mydemo/demoimg7.png'">
          <span class="police">
            <img src="../assets/images/police.png" alt="" />
          </span>
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502019956"
            target="_blank"
            >川公网安备51019002006209号</a
          >
        </div>

        <!-- cdchengdui.cn -->
        <div class="foot-text2" v-if="img == 'https://cdn.bzxd.tv/mydemo/demoimg11.png'">
          <span class="police">
            <img src="../assets/images/police.png" alt="" />
          </span>
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502019956"
            target="_blank"
            >川公网安备51019002006208号</a
          >
        </div>

 <!-- fhfenghua.cn -->
 <div class="foot-text2" v-if="img == 'https://cdn.bzxd.tv/mydemo/demoimg8.png'">
          <span class="police">
            <img src="../assets/images/police.png" alt="" />
          </span>
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502019956"
            target="_blank"
            >川公网安备51019002006215号</a
          >
        </div>

        <!-- haohehaohe.cn -->
 <div class="foot-text2" v-if="img == 'https://cdn.bzxd.tv/mydemo/demoimg10.png'">
          <span class="police">
            <img src="../assets/images/police.png" alt="" />
          </span>
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502019956"
            target="_blank"
            >川公网安备51019002006214号</a
          >
        </div>
             <!-- qyqiaoyuan.cn -->
 <div class="foot-text2" v-if="img == 'https://cdn.bzxd.tv/mydemo/demoimg9.png'">
          <span class="police">
            <img src="../assets/images/police.png" alt="" />
          </span>
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502019956"
            target="_blank"
            >川公网安备51019002006213号</a
          >
        </div>

        <!-- huanyueapp.cn -->
        <div class="foot-text2" v-if="img == 'https://cdn.bzxd.tv/mydemo/demoimg17.png'">
          <span class="police">
            <img src="../assets/images/police.png" alt="" />
          </span>
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31011502019956"
            target="_blank"
            >沪公网安备31010402333610号</a
          >
        </div>
        <!-- huanyuanapp.cn -->
        <div class="foot-text2" v-if="img == 'https://cdn.bzxd.tv/mydemo/demoimg13.png'">
          <span class="police">
            <img src="../assets/images/police.png" alt="" />
          </span>
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010402333622"
            target="_blank"
            >沪公网安备31010402333622号</a
          >
        </div>
          <div class="foot-text2" v-if="img == 'https://cdn.bzxd.tv/mydemo/demoimg14.png'">
          <span class="police">
            <img src="../assets/images/police.png" alt="" />
          </span>
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010402333624"
            target="_blank"
            >沪公网安备31010402333624号</a
          >
        </div>
          <div class="foot-text2" v-if="img == 'https://cdn.bzxd.tv/mydemo/demoimg18.png'">
          <span class="police">
            <img src="../assets/images/police.png" alt="" />
          </span>
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode="
            target="_blank"
            >沪公网安备31010402334257号</a
          >
        </div>
        <div class="foot-text2" v-if="img == 'https://cdn.bzxd.tv/mydemo/demoimg19.png'">
          <span class="police">
            <img src="../assets/images/police.png" alt="" />
          </span>
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode="
            target="_blank"
            >沪公网安备31010402334258号</a
          >
        </div>
        <div class="foot-text2" v-if="img == 'https://cdn.bzxd.tv/mydemo/demoimg20.png'">
          <span class="police">
            <img src="../assets/images/police.png" alt="" />
          </span>
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode="
            target="_blank"
            >沪公网安备31010402335052号
            </a
          >
        </div>
        <div class="foot-text2" v-if="img == 'https://cdn.bzxd.tv/mydemo/demoimg21.png'">
          <span class="police">
            <img src="../assets/images/police.png" alt="" />
          </span>
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode="
            target="_blank"
            >沪公网安备31011502402724号</a
          >
        </div>
        <div class="foot-text2" v-if="img == 'https://cdn.bzxd.tv/mydemo/demoimg22.png'">
          <span class="police">
            <img src="../assets/images/police.png" alt="" />
          </span>
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode="
            target="_blank"
            >沪公网安备31010402335054号
            </a
          >
        </div>
        <div class="foot-text2" v-if="img == 'https://cdn.bzxd.tv/mydemo/demoimg23.png'">
          <span class="police">
            <img src="../assets/images/police.png" alt="" />
          </span>
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode="
            target="_blank"
            >沪公网安备31010402335053号
            </a
          >
        </div>
        <div class="foot-text2" v-if="img == 'https://cdn.bzxd.tv/mydemo/demoimg24.png'">
          <span class="police">
            <img src="../assets/images/police.png" alt="" />
          </span>
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode="
            target="_blank"
            >沪公网安备31010402335090号
            </a
          >
        </div>
        <div class="foot-text2" v-if="img == 'https://cdn.bzxd.tv/mydemo/demoimg25.png'">
          <span class="police">
            <img src="../assets/images/police.png" alt="" />
          </span>
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode="
            target="_blank"
            >沪公网安备31010402335093号
            </a
          >
        </div>
        <div class="foot-text2" v-if="img == 'https://cdn.bzxd.tv/mydemo/demoimg26.png'">
          <span class="police">
            <img src="../assets/images/police.png" alt="" />
          </span>
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode="
            target="_blank"
            >沪公网安备31010402335091号
            </a
          >
        </div>
        <div class="foot-text2" v-if="img == 'https://cdn.bzxd.tv/mydemo/demoimg27.png'">
          <span class="police">
            <img src="../assets/images/police.png" alt="" />
          </span>
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode="
            target="_blank"
            >沪公网安备31010402335049号
            </a
          >
        </div>
        <div class="foot-text2" v-if="img == 'https://cdn.bzxd.tv/mydemo/demoimg28.png'">
          <span class="police">
            <img src="../assets/images/police.png" alt="" />
          </span>
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode="
            target="_blank"
            >沪公网安备31010402335050号</a
          >
        </div>
        <div class="foot-text2" v-if="img == 'https://cdn.bzxd.tv/mydemo/demoimg29.png'">
          <span class="police">
            <img src="../assets/images/police.png" alt="" />
          </span>
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode="
            target="_blank"
            >沪公网安备31010402335051号
            </a
          >
        </div>
        <div class="foot-text2" v-if="img == 'https://cdn.bzxd.tv/mydemo/demoimg30.png'">
          <span class="police">
            <img src="../assets/images/police.png" alt="" />
          </span>
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode="
            target="_blank"
            >沪公网安备31010402335092号
            </a
          >
        </div>

        <div class="foot-text3">@2024—{{ appname }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { AppInfo } from "../utils/Host";

export default {
  data() {
    return {
      appdown: null,
      show: 0,
      img: null,
      img_1: null,
      appname:null,
      active:1,
      click:false,
    };
  },
  created() {
    this.img = AppInfo.imgage;
    this.img_1 = AppInfo.imgage_1;
    this.icp = AppInfo.icp;
    this.appdown = AppInfo.appdown;
    this.appname = AppInfo.appname;

    console.log(AppInfo);
  },
  methods: {
    onxz1() {
      this.show = 0;
    },
    onxz2() {
      this.show = 1;
    },
    onxz3() {
      this.show = 2;
    },
    selectson(index){
      this.active = index
    },
    clickgo(){
      this.click = true
      setTimeout(() => {
        this.click = false
      }, 1000);
    },
    //下载
    onxiaz() {
      const fileUrl = this.appdown;
      const link = document.createElement("a");
      link.href = fileUrl;
      link.setAttribute("download", "taliao_tl_huaweiRelease.apk");
      console.log(link,"link")
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      console.log("下载中");
    },
  },
};
</script>
<style scoped lang="scss">
.herd {
  width: 100%;
  height: 90px;
  background-color: #fff;
  display: flex;
  font-size: 24px;
  position: relative;
  color: #b5b5b5;
}
.herd1 {
  display: flex;
  align-items: center;
}
.herdtext1 {
  position: relative;
  margin-left: 67px;
}
.herdtext2 {
  position: relative;
  margin-left: 67px;
}
.herd2 {
  position: absolute;
  top: 30px;
  right: 24px;
  color: #3a3a3a;
}

.span {
  width: 26px;
  height: 6px;
  background-color: #232323;
  border-radius: 3px;
  position: absolute;
  transform: translateX(-50%);
  left: 50%; 

}
.nr1 {
  width: 100%;
  height: 820px;
}
.nr1img {
  width: 100%;
  height: 100%;
}
.nr2 {
  width: 100%;
  height: 820px;
  background-color: #eeeeee;
  line-height: 820px;
  text-align: center;
}
.nr2text {
  color: #3a3a3a;
  font-size: 30px;
}
.foot {
  width: 100%;
  height: 85px;
  background-color: #fff;
  text-align: center;
  padding-top: 30px;
}
.foot-text1,
.foot-text2 {
  color: #5472ea;
  font-size: 15px;
}
.foot-text1 {
}
.foot-text2 {
  margin-top: 13px;
}
a {
  color: #5472ea;
  text-decoration: none;
}
.foot-text3 {
  font-size: 15px;
  color: #666;
  margin-top: 13px;
}
.police {
  position: relative;
  top: 3px;
}
.ys {
  color: #3a3a3a;
}
.my-swipe .van-swipe-item {
  color: #fff;
  font-size: 20px;
  height: 820px;
  /* line-height: 150px; */
  text-align: center;

  /* background-color: #39a9ed; */
}
.show2box{
  width: 381px;
  margin-left: 67px;
  position: relative;
  .select_title{
    margin-top:  54px;
    margin-bottom: 24px;
    font-size:24px ;
  }
  .select_wx{
    margin-top:  47px;
    margin-bottom: 14px;
    font-size:24px ;
  }
  .topinput{
    height: 54px;
    border: 1px solid #d8d8d8;
    border-radius: 10px;
    input{
      border-radius: 10px;
      font-size: 24px;
      height: 52px;
      width: 346px;
      border: none;
      margin-left: 28px;
    }
    ::-webkit-input-placeholder {
      /* WebKit, Blink, Edge */
      color: #9e9e9e;
      font-size: 24px;
    }
    
  }
  .menue_son{
    height: 71px;
    font-size:24px ;
    line-height: 71px;
    text-align: center;
    margin-bottom: 15px;
    background-color: #f3f3f3;
    border-radius: 10px;
    color: #1e1e1e;
  }
  .active{
    background-color: #ffeef5;
    border: 1px solid #eb2d79;
    color: #eb2d79;
  }
  .wx{
    height: 54px;
    width: 166px;
    color: white;
    font-size: 24px;
    line-height: 54px;
    text-align: center;
    background-color: #55bf5f;
    border-radius: 10px;
  }
  .clickgo{
    margin-top: 87px;
    height: 69px;
    color: white;
    font-size: 24px;
    line-height: 69px;
    text-align: center;
    background: linear-gradient(170deg,#ff7486 0%,#fc3fa2 100%);
    border-radius: 18px;
    margin-bottom: 80px;
  }
  .loding{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100px;
    height: 100px;
    background: #1e1e1e;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    line-height: 100px;
  }
}
</style>
